import request from '../utils/request'
import baseUrl from './baseUrl'

export const subClassification = (data) => request({
  url: baseUrl + '/hotelCate/selectAllByFatherId',
  method: 'POST',
  data
})

export const hotelCateList = (data) => request({
  url: baseUrl + '/hotelCate/selectForBack',
  method: 'POST',
  data
})
export const addHotel = (data) => request({
  url: baseUrl + '/hotelCate/add',
  method: 'POST',
  data
})
export const editHotelCate = (data) => request({
  url: baseUrl + '/hotelCate/add',
  method: 'POST',
  data
})
export const hotelDetails = (data) => request({
  url: baseUrl + '/hotelCate/selectForId',
  method: 'POST',
  data
})
export const delHotelCate = (data) => request({
  url: baseUrl + '/hotelCate/deleteById',
  method: 'POST',
  data
})
export const editSort = (data) => request({
  url: baseUrl + '/hotelCate/modifyDept',
  method: 'POST',
  data
})
export const changeStatus = (data) => request({
  url: baseUrl + '/hotelCate/upDownCate',
  method: 'POST',
  data
})

