import request from '../utils/request'
import baseUrl from './baseUrl'

export const getAllProvince = () => request({
  url: baseUrl + '/province/selectAll',
  method: 'POST',
})
export const getCityByProvince = (data) => request({
  url: baseUrl + '/city/selectCityByPro',
  method: 'POST',
  data
})
export const getAreaByCity = (data) => request({
  url: baseUrl + '/area/selectAllByCityCode',
  method: 'POST',
  data
})
export const getStreetByArea = (data) => request({
  url: baseUrl + '/street/selectAllByAreaCode',
  method: 'POST',
  data
})