import request from '../utils/request'
import baseUrl from './baseUrl'
/***
 * 酒店列表模块
 */
export const hotelList = (data) => request({
  url: baseUrl + '/hotel/selectForBack',
  method: 'POST',
  data
})
export const addHotel = (data) => request({
  url: baseUrl + '/hotel/add',
  method: 'POST',
  data
})
// 编辑酒店 
export const editHotel = (data) => request({
  url: baseUrl + '/hotel/add',
  method: 'POST',
  data
})
//修改排序 /hotel/modifyDept
export const editSort = (data) => request({
  url: baseUrl + '/hotel/modifyDept',
  method: 'POST',
  data
})
export const cahngeStatus = (data) => request({
  url: baseUrl + '/hotel/modifyStatus',
  method: 'POST',
  data
})
export const getHotelDetails = (data) => request({
  url: baseUrl + '/hotel/selectForId',
  method: 'POST',
  data
})
export const allHotel = (data) => request({
  url: baseUrl + '/hotel/selectAll',
  method: 'POST',
  data
})